import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Content from "../components/Layout/Content"

const Article = (props) => {
  const article = props.pageContext.article

  return (
    <Layout location={props.location}>
      <SEO title={article.title} description={article.description} />
      <div className="row">
        <div className="col text-center">
          <h1>{article.title}</h1>
        </div>
      </div>
      <Content content={article.content} />
    </Layout>
  )
}

export default Article
